/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { View, KeyboardAvoidingView, Platform, ScrollView, TouchableWithoutFeedback, Alert } from "react-native";
import { useForm, Controller, get } from "react-hook-form";
import { TextInput, useTheme, Title, Subheading, Text, Button, Snackbar, Switch } from "react-native-paper";
import debounce from "lodash/debounce";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { styles } from "./forms.style";
import { ProfileParamlist } from "../types"; // eslint-disable-line no-unused-vars
import { translate } from "../../../components/Localei18n/languagesFunctions";
import ConfirmDialog from "../../Auth/components/ConfirmDialog";
import { UserInfoContext } from "../../../context/userInfo";

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  navigation?: StackNavigationProp<ProfileParamlist>; // eslint-disable-line react/require-default-props
};

const EditContactsEmail = (props: Props) => {
  const theme = useTheme();
  const {
    control, handleSubmit, register, getValues, setValue, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  let unmounted = false;
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [inputData, setInputData] = useState(props.route.params); // eslint-disable-line react/prop-types
  const [confirmDialogOptions, setConfirmDialogOptions] = useState({
    visible: false,
    title: null,
    message: null,
    confirmFn: null,
    cancelFn: null,
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { userInfo: { uic, email } } = useContext(UserInfoContext);

  const hideDialog = () => {
    setConfirmDialogOptions({ ...confirmDialogOptions, visible: false });
  };

  const onSubmit = async (data: object) => {
    const mutation = gql`
      mutation updateEmail($formValue: FormContactsEmailInput!, $panelist_id: Int!, $status: Int, $uic: String){
        insertIgnoreFormsContacts(panelist_id: $panelist_id)
        insertIgnoreFormsContactsEmails(panelist_id: $panelist_id)
        updateVerificationStatus(status: $status, panelist_id: $panelist_id, uic: $uic)
        updateFormEmail(formValue: $formValue)
      }
    `;

    try {
      const response = await API.graphql(graphqlOperation(mutation, {
        formValue: { ...data, domain: (data.email).substr(data.email.indexOf("@") + 1, data.email.length), primary_email_address: data.primary_email_address ? 1 : 0 },
        panelist_id: inputData.panelist_id,
        status: 2,
        uic,
      }));

      if (response.data.updateFormEmail !== true) {
        if (unmounted) return false;
        setMessage(translate("Failed to update email."));
        setSnackbarVisible(true);
      }
    } catch (err) {
      setMessage(translate("Failed to update email."));
      setSnackbarVisible(true);
      console.log(["failed to update contacts", err, data]);
    }
  };

  const autoUpdate = useCallback(debounce(() => {
    if (Object.keys(errors).length === 0) {
      if (!unmounted) {
        onSubmit(getValues());
      }
    }
  }, 1000, { leading: false }), []);


  const handleDelete = () => {
    if (Platform.OS === "web") {
      setDeleteLoading(true);
      hideDialog();
      props.route.params.onDelete()
        .then((res) => {
          if (res === true) {
            // Analytics.logEvent("EmailUpdated");
            props.navigation.goBack();
            setDeleteLoading(false);
          } else if (res === false) {
            setMessage(translate("Failed to delete email."));
            setSnackbarVisible(true);
          }
        });
    } else {
      Alert.alert(
        translate("Confirm delete"),
        translate("Delete this email?"),
        [
          {
            text: translate("Cancel"),
            onPress: () => {},
            style: "cancel",
          },
          {
            text: translate("Delete"),
            onPress: () => {
              setDeleteLoading(true);
              props.route.params.onDelete()
                .then((res) => {
                  if (res === true) {
                    // Analytics.logEvent("EmailUpdated");
                    setDeleteLoading(true);
                    props.navigation.goBack();
                  } else if (res === false) {
                    setMessage(translate("Failed to delete email."));
                    setSnackbarVisible(true);
                  }
                });
            }
            ,
          },
        ],
        { cancelable: false }
      );
    }
  };


  useEffect(() => {
    register("email_id", { value: inputData.email_id });
    register("panelist_id", { value: inputData.panelist_id });
    return () => {
      unmounted = true;
    };
  }, []);

  const cancelSetEmailAsPrimary = () => {
    setValue("primary_email_address", false);
    hideDialog();
  };

  const handleSetEmailAsPrimary = (as_primary) => {
    const newData = { ...inputData, primary_email_address: as_primary };
    setInputData(newData);

    if (!unmounted) {
      onSubmit(getValues());
    }

    setConfirmDialogOptions({ ...confirmDialogOptions, visible: false });
  };

  const setEmailAsPrimaryHandler = (as_primary) => {
    if (Platform.OS === "web") {
      if (as_primary) {
        setConfirmDialogOptions({
          ...confirmDialogOptions,
          visible: true,
          title: translate("Confirm"),
          message: translate("Make this email primary?"),
          confirmFn: () => handleSetEmailAsPrimary(as_primary),
          cancelFn: cancelSetEmailAsPrimary,
        });
      }
    } else {
      Alert.alert(
        translate("Confirm"),
        translate("Make this email primary?"),
        [
          {
            text: translate("Cancel"),
            onPress: () => {},
            style: "cancel",
          },
          {
            text: translate("Set as Primary"),
            onPress: () => {
              setInputData({ ...inputData, primary_email_address: as_primary });
              handleSetEmailAsPrimary(as_primary);
              autoUpdate();
            // Analytics.logEvent("EmailUpdated");
            }
            ,
          },
        ],
        { cancelable: false }
      );
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.root,
        { backgroundColor: theme.colors.background },
      ]}
    >
      <ScrollView style={[styles.scrollWrapper]} contentContainerStyle={{ alignItems: "center" }}>
        <View style={[styles.scrollInnerResponsive]}>
          <View style={styles.titleWRapper}>
            <Title style={styles.title}>{translate("Update Email")}</Title>
            <Subheading style={styles.subheadingRegular}>
              {translate("Any changes to your email is subject for verification.")}
            </Subheading>
          </View>

          <View style={styles.formField}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  returnKeyType="next"
                  mode="outlined"
                  style={styles.formInput}
                  onBlur={onBlur}
                  disabled={inputData.email === email}
                  onChangeText={(ev) => {
                    onChange(ev);
                    autoUpdate();
                  }}
                  value={value}
                  label={translate("Email Address")}
                />
              )}
              name="email"
              defaultValue={inputData.email}
              rules={{
                required: { value: true, message: translate("Please enter your email address") },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />

            {errors.email?.message?.length > 0
                && (<Text style={{ color: "red", marginTop: 4 }}>{errors.email?.message}</Text>)}

          </View>

          <View style={styles.formField}>
            <View>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {inputData.primary_email_address
                      ? (
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          <MaterialCommunityIcons name="check-bold" size={24} color={theme.colors.primary} />
                          <Text style={{ fontSize: 18, fontWeight: "700", color: theme.colors.primary }}>
                            {translate("Primary")}
                          </Text>
                        </View>
                      )
                      : (
                        <>
                          <TouchableWithoutFeedback onPress={() => {
                            setEmailAsPrimaryHandler(!value);
                            onChange(!value);
                          }}
                          >
                            <View
                              pointerEvents={Platform.OS === "web" ? "auto" : "box-only"}
                              style={{ flexDirection: "row", alignItems: "center" }}
                            >
                              <Switch
                                color={theme.colors.primary}
                                value={value}
                              />
                              <View pointerEvents={Platform.OS === "web" ? "auto" : "box-only"}>
                                <Text style={{ fontSize: 17, fontWeight: "500", marginLeft: 12 }}>{translate("Set as Primary")}</Text>
                              </View>
                            </View>
                          </TouchableWithoutFeedback>
                        </>
                      )}
                  </>
                )}
                name="primary_email_address"
                defaultValue={inputData.primary_email_address}
              />

            </View>
          </View>

          <View style={styles.formActionField}>
            <Button
              disabled={inputData.primary_email_address || !!deleteLoading || inputData.email === email}
              onPress={Platform.OS === "web" ? () => setConfirmDialogOptions({
                ...confirmDialogOptions,
                visible: true,
                title: translate("Confirm Delete"),
                message: translate("Delete this address?"),
                confirmFn: handleDelete,
                cancelFn: hideDialog,
              }) : handleDelete}
              contentStyle={[styles.button]}
              dark
              color="red"
              loading={deleteLoading}
            >
              {translate("Delete")}
            </Button>
          </View>

        </View>
      </ScrollView>

      <ConfirmDialog
        style={{ maxWidth: 500, marginHorizontal: "auto" }}
        visible={confirmDialogOptions.visible}
        cancelEvt={confirmDialogOptions.cancelFn}
        confirmEvt={confirmDialogOptions.confirmFn}
        title={confirmDialogOptions.title}
        message={confirmDialogOptions.message}
      />

      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
        action={{
          label: "×",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {message}
      </Snackbar>
    </KeyboardAvoidingView>
  );
};

export default EditContactsEmail;
