import React, { useRef, useEffect } from "react";
import { Platform, View } from "react-native";

function useClickTracker(ref, user, setActive) {
  useEffect(() => {
    function handleClickOutside() {
      if (user) { setActive(true); }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const ClickTracker = (props: any) => {
  if (Platform.OS === "web") {
    const wrapperRef = useRef(null);
    useClickTracker(wrapperRef, props.user, props.setActive);

    return <View style={{ display: "contents" }} ref={wrapperRef}>{props.children}</View>;
  }

  return <>{props.children}</>;
};

export default ClickTracker;
