import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
  Linking,
  Platform,
} from "react-native";
import { Button, useTheme } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { translate } from "./Localei18n/languagesFunctions";

type Props = {
  theme: string;
};

const CookieConsent = (props: Props) => {
  const [showCookieWarning, setShowCookieWarning] = useState(false);
  const [consentContainerStyle, setConsentContainerStyle] = useState({});
  const [consentButtonStyle, setConsentButtonStyle] = useState({});
  const uTheme = useTheme();


  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    window.document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = window.document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const cookieConsentClosed = async () => {
    if (Platform.OS === "web") {
      const consent = getCookie("hcpCookiesConsentClosed");
      return consent;
    }
    const consent = await AsyncStorage.getItem("hcpCookiesConsentClosed");
    return consent;
  };

  const dismissCookieWarning = () => {
    if (Platform.OS === "web") {
      setCookie("hcpCookiesConsentClosed", "1", 60);
    } else {
      AsyncStorage.setItem("hcpCookiesConsentClosed", "1");
    }
    setShowCookieWarning(false);
  };

  const onResize = () => {
    if (Dimensions.get("window").width < 850) {
      setConsentButtonStyle({ marginTop: 8 });
      setConsentContainerStyle({ flexDirection: "column" });
    } else {
      setConsentButtonStyle({ marginLeft: 16 });
      setConsentContainerStyle({});
    }
  };

  useEffect(() => {
    cookieConsentClosed()
      .then((consentClosed) => {
        setShowCookieWarning(consentClosed === null || parseInt(consentClosed, 10) === 0);
      });

    onResize();

    Dimensions.addEventListener("change", onResize);

    return () => {
      Dimensions.removeEventListener("change", onResize);
    };
  }, []);

  return (showCookieWarning
    && (
    <SafeAreaView>
      <View style={[styles.cookieConsentStyle, {
        backgroundColor: props.theme === "dark" ? "#1e1d1d" : "#ebebeb",
      }, props.theme === "light" && {
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: "#d8d8d8",
      }, consentContainerStyle]}
      >
        <Text style={[{ textAlign: "center" },
          { color: uTheme.colors.text }]}
        >
          {translate("This site uses cookies, as explained in our")}
          {" "}
          <TouchableWithoutFeedback onPress={() => {
            const link = "https://www.ppd.com/who-we-are/company-resources/privacy-policy/";
            if (Platform.OS === "web") {
              window.open(link, "privacy-policy").focus();
            } else {
              Linking.openURL(link);
            }
          }}
          >
            <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>{translate("Privacy Policy")}</Text>
          </TouchableWithoutFeedback>
          {". "}
          {translate("If you agree to our use of cookies, please close this message and continue to use.")}
        </Text>

        <Button
          mode="contained"
          compact
          uppercase={false}
          icon="check-bold"
          style={consentButtonStyle}
          onPress={() => {
            dismissCookieWarning();
          }}
        >
          {translate("Accept & Close")}
        </Button>
      </View>
    </SafeAreaView>
    )
  );
};

const styles = StyleSheet.create({
  cookieConsentStyle: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CookieConsent;
