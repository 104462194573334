import React, { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import UserInactivity from "react-native-user-inactivity";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { SessionContext, SessionContextDialog } from "../../context/SessionContext";
import UserService from "../../helpers/user.service";
import ClickTracker from "../../helpers/ClickTracker";

type SessionManagerProp= {
  children: any;
  user: boolean;
  active: boolean;
}

const inactivityMins = 15;
const inactivityMs = (inactivityMins * 60 * 1000); // 15minutes, 1min = 60seconds, 1sec = 1000ms

const messages = [
  "You're only allowed to have 2 simultaneuos logins",
  `You're inactive for ${inactivityMins} minutes.`,
  "Your account and all data has been deleted.",
];

const SessionContextInner = ({ children, user, active }: SessionManagerProp) => {
  const {
    sessionDlg, handleSessionDeactivation, message, setMessage, closeDialog, setShowMessage,
  } = useContext(SessionContext);

  const handleCloseDialog = async () => {
    closeDialog(false);
    setMessage(undefined);
    if (Platform.OS === "web") setShowMessage(undefined);
  };

  return (
    <>
      {!user && (children)}
      <SessionContextDialog
        visible={sessionDlg}
        closeDialog={handleCloseDialog}
        message={message}
      />
      {user && (
        <UserInactivity
          isActive={active}
          timeForInactivity={
            inactivityMs // equivalent to number of millisecond before signing out 1000ms = 1 second
        }
          onAction={(isActive) => {
            if (!isActive) {
              handleSessionDeactivation(1);
            }
          }}
          // style={{ height: 0 }}
        >
          {children}
        </UserInactivity>
      )}
    </>


  );
};

const SessionManager = ({
  handleSignout, children, userInfo, user, showMessage, setShowMessage, setSignOutInactiveFromWeb,
}: any) => {
  const [sessionDlg, setSessionDlg] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [active, setActive] = useState(true);
  const { checkUser } = UserService();

  const deleteAccDeletionRequest = async () => {
    const panelist_id = userInfo?.["custom:panelist_id"];

    const mutation = gql`
      mutation deleteAccDeletionRequest($panelist_id: Int){
        deleteAccDeletionRequest(panelist_id: $panelist_id)
      }
    `;

    try {
      const response = await API.graphql(graphqlOperation(mutation, { panelist_id }));
    } catch (err) {
      console.log(err);
    }
  };

  const checkHasApprovedHcpDeletion = async () => {
    try {
      const panelist_id = userInfo?.["custom:panelist_id"];
      const qry = gql`
          query ($panelist_id: Int, $status: Int){
            getHcpDeletionRequestCount(panelist_id: $panelist_id, status: $status)
          }`;

      const response = await API.graphql(graphqlOperation(qry, {
        panelist_id,
        status: 2,
      }));

      return response?.data?.getHcpDeletionRequestCount > 0;
    } catch (e) {
      console.warn("error fetching:", JSON.stringify(e));
      return false;
    }
  };

  const handleSessionDeactivation = async (messageIndex) => {
    if (Platform.OS === "web") {
      await handleSignout();
      setShowMessage(messages[messageIndex]);
      setSignOutInactiveFromWeb(true); // set to true to trigger redirect from landing to home
    } else {
      setMessage(messages[messageIndex]);
      setSessionDlg(true);
      await handleSignout();
    }
  };

  const sessionPref = React.useMemo(() => ({
    sessionDlg,
    message,
    handleSessionDeactivation,
    setMessage,
    // handleSignout: () => props.handleSignout(),
    closeDialog: setSessionDlg,
    setShowMessage,
  }),
  [sessionDlg, message]);

  useEffect(() => {
    // if (!user && Platform.OS === "web" && showMessage >= 0) {
    //   console.log(`Message Index: ${showMessage}`);
    //   setMessage(messages[showMessage]);
    //   setSessionDlg(true);
    // }
  }, [user]);

  const validateUser = async () => {
    let hasApprovedHcpDeletion = false;
    if (await checkUser() === false) {
      hasApprovedHcpDeletion = await checkHasApprovedHcpDeletion();

      // check first if there is an approved deletion request
      // to return message index 0 or 2
      if (hasApprovedHcpDeletion) {
        handleSessionDeactivation(2);
      } else {
        handleSessionDeactivation(0);
      }
    }
  };

  const MINUTE_MS = 1000 * (60 * 1); // ms * (seconds * minutes)
  useEffect(() => {
    const interval = setInterval(() => {
      if (user) {
        validateUser();
      }
    }, MINUTE_MS);

    return () => {
      // clearInterval(interval);
    };
  }, [user]); // user

  return (
    <SessionContext.Provider value={sessionPref}>
      <SessionContextInner user={user} active={active}>
        <ClickTracker user={user} setActive={setActive}>
          {children}
        </ClickTracker>
      </SessionContextInner>
    </SessionContext.Provider>
  );
};


export default SessionManager;
