/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, { useState, useEffect, useContext } from "react";
import {
  View,
  TouchableHighlight,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  Platform,
} from "react-native";
import {
  TextInput,
  Button,
  useTheme,
  Title,
  Subheading,
} from "react-native-paper";
import PropTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import { useForm, Controller } from "react-hook-form";
import FormField from "../../components/FormValidator/FormField";
import { HCPPortalTheme } from "../../components";
import { translate } from "../../components/Localei18n/languagesFunctions";
import ErrorComponent from "../../components/ErrorComponent";
import { AuthContext } from "./AuthContext";
import AuthLogoHolder from "./AuthLogoHolder";
import AlertBox from "../../components/Alert";

const styles = StyleSheet.create({
  input: {
    height: 37,
    width: "100%",
    backgroundColor: "rgb(239, 239, 239)",
    padding: 5,
    borderColor: "rgb(220, 218, 218)",
    borderRadius: 3,
  },
  title: {
    textAlign: "left",
    // color: "rgb(130, 130, 130)",
    color: "#4e4e4e",
    fontSize: 16,
  },
  button: {
    backgroundColor: "rgb(26, 158, 168)",
    height: 40,
    color: "white",
    borderRadius: 3,
    padding: 10,
  },
});

export const UserCheck = (props: any) => {
  const [loading, setLoading] = useState(false);
  const { setAuthData, setAuthError, setAuthStage } = useContext(AuthContext);
  const [error, setError] = useState("");
  let unmounted = false;

  const uTheme = useTheme();

  const {
    control, watch, trigger, getValues, setValue, formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const changeState = ($nextStage, data) => {
    if (!unmounted) {
      props.onStateChange($nextStage, data);
    }
  };

  const handleGotoSignUp = () => {
    changeState("signUp", "");
  };

  const handleGotoSignIn = () => {
    changeState("signIn", "");
  };

  const onSubmit = async () => {
    await trigger();
    if (Object.keys(errors).length === 0) {
      const { email } = getValues();
      if (!unmounted && email !== "") {
        setLoading(true);

        try {
          await Auth.signIn({
            username: email.toLowerCase(),
            password: email.substring(0, 3),
          });
        } catch (er) {
          props.setEmail(email);
          if (er.code === "UserNotConfirmedException") {
          // console.log("Signin error", er.code);
            setAuthData({ email: email.toLowerCase() });
            // add update usecontext here
            setAuthStage(2);
            setAuthError(er);
            changeState("signUp", "");
            return;
          }
          if (er.message === "User does not exist.") {
            handleGotoSignUp();
            return;
          }
          handleGotoSignIn();
        }
      }
    }
  };

  useEffect(() => () => {
    unmounted = true;
  }, []);

  return (
    props.authState === "userCheck" && (
      <ScrollView
        keyboardShouldPersistTaps="never"
        style={[HCPPortalTheme.section, {}]}
        contentContainerStyle={{ flex: 1 }}
      >

        <View style={{ flex: 1 }}>

          <View style={{ height: "40%", justifyContent: "flex-end" }}>

            <AuthLogoHolder style={{ justifyContent: "center" }} />


            <View style={{ justifyContent: "center", marginTop: 20, marginBottom: 10 }}>
              <Title style={{ textAlign: "center", fontWeight: "700" }}>{translate("Sign in")}</Title>
              <Subheading style={{ textAlign: "center" }}>
                {translate("to continue to HCP Portal")}
              </Subheading>
            </View>

          </View>

          <View style={{ flex: 1 }}>
            <FormField bottomSpace errors={errors?.email} style={{ marginTop: 20 }}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    returnKeyType="go"
                    keyboardType="email-address"
                    mode="flat"
                    label={translate("Enter your email")}
                    placeholder="e.g. johndoe@email.com"
                    textContentType="emailAddress"
                    onBlur={onBlur}
                    onKeyPress={(e) => {
                      if (e.nativeEvent.key === "Enter") {
                        onSubmit();
                      }
                    }}
                    onChangeText={onChange}
                    // error={errors.email?.message}
                    value={value}
                    autoCapitalize="none"
                  />
                )}
                name="email"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: translate("Email is mandatory."),
                  },
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate("Invalid email address"),
                  },
                }}
              />
            </FormField>

            <View
              style={{
                marginBottom: 15,
                flexDirection: "row",
              }}
            >
              <Button
                compact
                loading={loading}
                disabled={loading}
                mode="contained"
                style={{ flex: 1 }}
                contentStyle={{ paddingVertical: 8 }}
                onPress={onSubmit}
                color={uTheme.colors.primary}
              >
                {translate("Next")}
              </Button>
            </View>

            <AlertBox type="error">{error}</AlertBox>
          </View>
        </View>


      </ScrollView>
    )
  );
};


UserCheck.propTypes = {
  onStateChange: PropTypes.func,
  setEmail: PropTypes.func.isRequired,
};

UserCheck.defaultProps = { onStateChange: () => {} };

export default UserCheck;
